@font-face {
  font-family: "Crimson Pro";
  src: url("../fonts/Crimson_Pro/CrimsonPro-VariableFont_wght.ttf");
}

.app {
  text-align: center;
  font-family: "Crimson Pro";
  background-color: #1d293f;
}

.name {
  margin-bottom: 0;
  font-size: 4rem;
}

.professional-title {
  margin-top: 0;
  font-size: 1.6rem;
  color: #d3d3d3;
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media (max-width: 805px) {
  .app-container {
    transform: translateY(-6%);
  }
  .name {
    font-size: 2.2rem;
  }
  .professional-title {
    font-size: 1rem;
  }
}

.animate-home-component {
  animation-duration: 3s;
  animation-name: animate-fade;
  animation-delay: .5s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}